<script>
  import SwiperCore from "swiper";
  // import {
  //   Swiper,
  //   SwiperSlide
  // } from "swiper/vue";
  import "swiper/swiper-bundle.css";
  import Multiselect from "@vueform/multiselect";
  import "@vueform/multiselect/themes/default.css";

  SwiperCore.use([]);
  // import flatPickr from "vue-flatpickr-component";
  // import {
  //   SimpleBar
  // } from "simplebar-vue3";
  import "flatpickr/dist/flatpickr.css";
  // import {
  //   CountTo
  // } from "vue3-count-to";

  import Layout from "../../../layouts/main.vue";
  import axios from "axios";
  import Swal from "sweetalert2";

  // import Scans from "./scans";

  export default {
    components: {
      // CountTo,
      Layout,
      Multiselect,
      // Swiper,
      // SwiperSlide,
      // flatPickr,
      // SimpleBar,
      // Scans,
    },
    beforeMount() {
      this.setSelectedPeriod('month')
    },
    data() {
      return {
        filterVerdicts: ['malicious', 'suspicious'],
        monthOffset: 0,
        displayDate: "",
        selectedPeriod: "month",
        selectedTenant: null,
        verdicts: [],
        title: "SOC",
        items: [{
            text: "Soc",
            href: "/",
          },
          {
            text: "Soc",
            active: true,
          },
        ],
        date: null,
      };
    },
    methods: {
      inspectEmail(val) {
        const postData = {
          email_id: val
        }
        axios.post(process.env.VUE_APP_API_BASE_URL+'/management/v1.0.0/soc/inspect', postData).then((data) => {
          console.log(data.data.content);
        }).catch((er) => {
          console.log(er)
        });
      },
      clickFalsePositive(val) {
        this.removeBanner(val);
        this.refreshData();
      },
      formatEpoch(epoch) {
        const newDate = new Date(epoch*1000);
        return newDate.toLocaleString();
      },
      removeBanner(val) {
        const postData = {
          email_id: val
        }
        axios.post(process.env.VUE_APP_API_BASE_URL+'/management/v1.0.0/soc/false_positive', postData).then((data) => {
          console.log(data);
        }).catch((er) => {
          console.log(er)
        });
      },
      emailHistory(val) {
        const postData = {
          usergroup_id: val,
        }
        axios.post(process.env.VUE_APP_API_BASE_URL+'/management/v1.0.0/soc/email_history', postData).then((data) => {
          console.log(data);
        }).catch((er) => {
          console.log(er)
        });
      },
      finishDataIntegration(val) {
        const postData = {
          usergroup_id: val,
        }
        axios.post(process.env.VUE_APP_API_BASE_URL+'/management/v1.0.0/soc/finish_data_integration', postData).then((data) => {
          console.log(data);
        }).catch((er) => {
          console.log(er)
        });
      },
      addImitationList(usergroup_id, json) {
        const postData = {
          usergroup_id: usergroup_id,
          json: json
        }
        axios.post(process.env.VUE_APP_API_BASE_URL+'/management/v1.0.0/soc/imitation_list', postData).then((data) => {
          console.log(data);
        }).catch((er) => {
          console.log(er)
        });
      },
      addTrustedList(usergroup_id, json) {
        const postData = {
          usergroup_id: usergroup_id,
          json: json
        }
        axios.post(process.env.VUE_APP_API_BASE_URL+'/management/v1.0.0/soc/trusted_list', postData).then((data) => {
          console.log(data);
        }).catch((er) => {
          console.log(er)
        });
      },
      displayBoolean(val) {
        if (val)
          return this.$t('t-yes');
        else
          return this.$t('t-no')
      },
      resetPeriodOffset() {
        this.monthOffset = 0;
        this.updateDisplayDate();
        // this.$refs.scans.setMonthOffset(this.monthOffset);
      },
      previousPeriodOffset() {
        if (this.selectedPeriod === 'month') {
          this.monthOffset--;
        } else {
          this.monthOffset = this.monthOffset - 12;
        }
        this.updateDisplayDate();
        // this.$refs.scans.setMonthOffset(this.monthOffset);
        // this.$refs.scans.getPerformance();
        this.refreshData()
      },
      nextPeriodOffset() {
        if (this.selectedPeriod === 'month') {
          this.monthOffset++;
        } else {
          this.monthOffset = this.monthOffset + 12;
        }
        this.updateDisplayDate();
        // this.$refs.scans.setMonthOffset(this.monthOffset);
        // this.$refs.scans.getPerformance();
        this.refreshData()
      },
      refreshData() {
        // this.$refs.scans.getPerformance();
        // TODO Workaround timeout to prevent 401
        Swal.fire({
          title: this.$t('t-licenses-loading'),
          showCancelButton: false,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          willOpen: () => {
            Swal.showLoading();
          },
        });
        setTimeout(() => {
          this.getStatistics();
        }, 100);

      },
      setSelectedPeriod(period) {
        this.resetPeriodOffset();
        this.selectedPeriod = period;
        this.updateDisplayDate();
        this.refreshData()
        // this.$refs.scans.setSelectedPeriod(this.selectedPeriod);
      },
      updateDisplayDate() {
        const date = new Date();
        if (this.selectedPeriod === "month") {
          const pastDate = new Date(date);
          pastDate.setMonth(pastDate.getMonth() + this.monthOffset);
          this.displayDate = pastDate.toLocaleString('default', { month: 'long' }) + ", " + pastDate.getFullYear();
        } else if (this.selectedPeriod === "year") {
          const pastDate = new Date(date);
          pastDate.setMonth(pastDate.getMonth() + this.monthOffset);
          this.displayDate = pastDate.getFullYear();
        }
      },
      toggleTenant(usergroup_id) {
        if (this.selectedTenant) {
          this.selectedTenant = null;
        } else {
          this.selectedTenant = usergroup_id;
        }
      },
      getStatistics() {
        const currentDate = new Date();
        const pastDate = new Date(currentDate);
        pastDate.setMonth(pastDate.getMonth() + this.monthOffset);
        const year = pastDate.getFullYear();
        const month = pastDate.getMonth() + 1;
        const postData = {
          period: this.selectedPeriod,
          year: ""+year,
          month: ""+month,
          verdicts: this.filterVerdicts
        }
        axios.post(process.env.VUE_APP_API_BASE_URL+'/management/v1.0.0/soc/list', postData).then((data) => {
          Swal.close();

          this.verdicts = data.data.verdicts;
        }).catch((er) => {
          Swal.close();
          console.log(er)
        });
      },
    },
  };
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col">
        <div class="h-100">

          <div class="row mb-3 pb-1">
            <div class="col-12">
              <div class="d-flex align-items-lg-center flex-lg-row flex-column">
                <div>
                  <h4 class="fs-16 mb-1">{{ $t("t-menu-licenses") }}</h4>
                </div>
                <div class="flex-grow-1">
                </div>
                <div class="mt-3 mt-lg-0">
                  <form action="javascript:void(0);">
                    <div class="row g-3 mb-0 align-items-center">
                      <div>
                        <div class="d-inline cursor-pointer" @click="previousPeriodOffset">
                          <i class="mdi mdi-chevron-left fs-6 me-1"></i>
                        </div>
                        <div class="d-inline">{{displayDate}}</div>
                        <div class="d-inline cursor-pointer" @click="nextPeriodOffset" v-if="monthOffset < 0">
                          <i class="mdi mdi-chevron-right fs-6 ms-1"></i>
                        </div>
                        <button type="button" class="btn btn-sm ms-3 me-1 btn-soft-primary text-dark" v-bind:class="{active: selectedPeriod==='month'}" @click="setSelectedPeriod('month')">
                          {{ $t("t-licenses-month") }}
                        </button>
                        <div class="btn-soft-primary btn btn-sm text-dark" @click="refreshData()">
                          <i class="bx bx-refresh fs-5"></i>
                        </div>
                      </div>
                      <!--end col-->
                    </div>
                    <!--end row-->
                  </form>
                </div>
              </div>
              <!-- end card header -->
            </div>
            <!--end col-->
          </div>
          <!--end row-->


          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-header">
                  Add to trusted sender list
                </div>
                <div class="card-body">
                  <div>
                    <textarea ref="trusted_list_json" class="form-control" placeholder="['sender@example.com', 'sender@example.com']" rows="3"></textarea>
                  </div>
                  <div class="input-group">
                    <input type="text" ref="trusted_list_usergroup_id" placeholder="Usergroup ID" autocomplete="off" class="form-control">
                    <span class="input-group-text cursor-pointer" @click="addTrustedList($refs.trusted_list_usergroup_id.value, $refs.trusted_list_json.value)">Verwerk</span>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header">
                  Add to imitation list
                </div>
                <div class="card-body">
                  <div>
<!--                    <label class="form-label">Example Textarea</label>-->
                    <textarea ref="imitation_list_json" class="form-control" placeholder="JSON" rows="3"></textarea>
                  </div>
                  <div class="input-group">
                    <input type="text" ref="imitation_list_usergroup_id" placeholder="Usergroup ID" autocomplete="off" class="form-control">
                    <span class="input-group-text cursor-pointer" @click="addImitationList($refs.imitation_list_usergroup_id.value, $refs.imitation_list_json.value)">Verwerk</span>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header">
                  Retrieve email sender history
                </div>
                <div class="card-body">
                  <div class="input-group">
                    <input type="text" ref="email_history" placeholder="Usergroup ID" autocomplete="off" class="form-control">
                    <span class="input-group-text cursor-pointer" @click="emailHistory($refs.email_history.value)">Verwerk</span>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header">
                  Finish data integration
                </div>
                <div class="card-body">
                  <div class="input-group">
                    <input type="text" ref="data_integration" placeholder="Usergroup ID" autocomplete="off" class="form-control">
                    <span class="input-group-text cursor-pointer" @click="finishDataIntegration($refs.data_integration.value)">Finish</span>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header">
                  Undo false positive
                </div>
                <div class="card-body">
                  <div class="input-group">
                    <input type="text" ref="remove_banner" placeholder="Mail ID" autocomplete="off" class="form-control">
                    <span class="input-group-text cursor-pointer" @click="removeBanner($refs.remove_banner.value)">Verwerk</span>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-body">
                  <div style="width: 500px;">
                    <span class="text-muted flex-shrink-0">Filter: </span>
                    <Multiselect class="form-control"
                                 style="min-width:300px; padding: 0;"
                                 :close-on-select="true"
                                 :searchable="true"
                                 mode="tags"
                                 v-model="filterVerdicts"
                                 @change="refreshData"
                                 :options="[
                                                  { value: 'trusted', label: 'Trusted' },
                                                  { value: 'neutral', label: 'Neutral' },
                                                  { value: 'suspicious', label: 'Suspicious' },
                                                  { value: 'malicious', label: 'Malicious' },
                                                ]"
                    />
                  </div>
                  <!-- Table Nesting -->
                  <table class="table table-nowrap">
                    <thead class="table-light">
                    <tr>
                      <th scope="col" style="width: 100px;">Verdict</th>
                      <th scope="col" style="width: 100px;">Type</th>
                      <th scope="col">Status</th>
<!--                      <th scope="col" style="width: 120px;">{{ $t('t-licenses-users') }}</th>-->
                      <th scope="col">Detection</th>
                      <th scope="col">Information</th>
                      <th scope="col" style="width: 120px;"></th>
                      <th scope="col" style="width: 120px;"></th>
                      <th scope="col"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-for="verdict in verdicts" :key="verdict.id">
                      <tr :class="{'table-active': selectedTenant === verdict.id}">
                        <td><i class="ri-information-line fs-14 cursor-pointer" v-b-tooltip.hover :title="'<span style=\'text-align: left; font-weight: bold;\'>' + verdict.id + '</span>'"></i></td>
                        <td>{{verdict.status}}</td>
                        <td><span v-if="verdict.link_id">Link</span><span v-if="verdict.email_id">Email</span></td>
                        <td><i class="ri-information-line fs-14 cursor-pointer" v-b-tooltip.hover :title="'<span style=\'text-align: left; font-weight: bold;\'>' + JSON.stringify(verdict.detection, null, 2) + '</span>'"></i></td>
                        <td><i class="ri-information-line fs-14 cursor-pointer" v-b-tooltip.hover :title="'<span style=\'text-align: left; font-weight: bold;\'>' + (verdict.link ? JSON.stringify(verdict.link, null, 2) : '') + (verdict.email ? JSON.stringify(verdict.email, null, 2) : '') + '</span>'"></i></td>
                        <td>{{formatEpoch(verdict.created)}}</td>
                        <td class="cursor-pointer"><span v-if="verdict.email_id" @click="clickFalsePositive(verdict.email_id)">False positive</span></td>
                        <td class="cursor-pointer"><span v-if="verdict.email_id" @click="inspectEmail(verdict.email_id)">Inspect</span></td>
<!--                        <td v-html="selectedTenant === usergroup.usergroup_id ? $t('t-licenses-users-hide') : $t('t-licenses-users-show')"></td>-->
                      </tr>
                    </template>

                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

        </div>
        <!-- end .h-100-->
      </div>
      <!-- end col -->

    </div>
  </Layout>
</template>